const navbar_top = document.querySelector(".navbar-toggler")
const main_body = document.querySelector(".main-body")
const nav_right = document.querySelector(".nav-right")
const menu_bar = document.querySelector("#menu-bar")
const sidebar_bar = document.querySelector("#sidebar-bar")
const sidebar = document.querySelector(".sidebar")
const remove_bell = document.querySelector("#remove-bell")
navbar_top?.addEventListener("click", () => {
  if (navbar_top.getAttribute("aria-expanded") === "true") {
    main_body.classList.add("active-navbar")
    nav_right.classList.add("active-navbar")
    remove_bell.classList.remove("d-none")
    
    menu_bar.classList.remove("ti-align-justified")
    menu_bar.classList.remove("ti")
    menu_bar.classList.add("fal")
    menu_bar.classList.add("fa-times")
  } else {
    main_body.classList.remove("active-navbar")
    nav_right.classList.remove("active-navbar")
    remove_bell.classList.add("d-none")
    
    menu_bar.classList.remove("fa-times")
    menu_bar.classList.remove("fal")
    menu_bar.classList.add("ti")
    menu_bar.classList.add("ti-align-justified")
  }
})

sidebar_bar?.addEventListener("click", ()=> {
  sidebar.classList.toggle("active")
})

if (window.location.pathname === '/') {
  main_body.classList.add("index")
}


function openCity(evt, cityName) {
  var i, tabcontent;
  tabcontent = document.getElementsByClassName("tab_content");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  
  document.getElementById(cityName).style.display = "block";
  document.querySelectorAll(".tab_links").forEach(item => {
    item.classList.remove("bg-f1f1f5");
    item.classList.remove("text-primary");
  })
  
  evt.currentTarget.classList.add("bg-f1f1f5")
  evt.currentTarget.classList.add("text-primary")
  evt.currentTarget.classList.remove("text-a1a6b0")
}

